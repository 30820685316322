import parseMs from 'parse-ms'
import addZero from 'add-zero'
import config from './config'
import qs from 'query-string'
import ru from 'resolve-pathname'
import uj from 'url-join'
import uparse from 'url-parse'
import querystring from 'querystring'
import store, { history } from '../store'
import CONFIG from '../config'
import {
  toggleStationPlayback,
  addPrevTrackPointer,
  removePrevTrackPointer
} from '../store/modules/stations'
import { addError } from '../store/modules/modal'
import { setLanguage } from '../store/modules/lng'
import gt from 'lodash/get'
import isEqual from 'lodash/isEqual'
import random from 'lodash/random'
import isNumber from 'lodash/isNumber'
import {
  reasonCodes,
  setPlaybackEvent,
  isDirectedPlay as userDirected,
  toggleShuffleStatus,
  loadTrackDefinition,
  addTrackToQ,
  deltaQIdx,
  skipTrack
} from '../store/modules/tracks'
import { setPlayable } from '../store/modules/playable'
import { getTrackInstance, getTrackContainerChunkDescription } from '../pages/Playback/selectors'
import { setChildNode } from '../store/modules/music'
import { requestAccessToken } from '../store/modules/auth'
import ERRORS from '../errors/messages'

export function getParam (param) {
  const parsed = qs.parse(document.location.search)
  if (parsed && parsed[param]) return parsed[param]
  else return undefined
}

export function resize(test) {
  const parts = test.toString().match(/^(\d+)(cm|mm|in|px|pt|pc|em|ex|ch|rem|vh|vw|vmin|vmax|%)?$/)
  if(!parts) {
    console.warn(`resize: test: ${test} is not a valid input`)
    return test;
  }

  const num = Number(parts[1])
  const unit = parts[2]
  let out = num
  if (window.innerWidth >= 1920) {
    out = Math.round(1080 / 720 * num)
  }

  if (unit) {
    out = `${out}${unit}`
  }

  return out
}

export function pollToken() {
  const { getState, dispatch } = store;
  const { access_token } = getState().auth
  if (access_token) {
    dispatch(requestAccessToken())
  }
}

export function useNativeVideoTag() {
  return navigator.userAgent.search(/Web0S/i) > -1
}

export function transcodeImg(uri) {
  return `${CONFIG.transcode.image_url}${uri}`
}

export function getImageResolution() {
  // supported Amazon resolutions
  const amz_res = [48, 60, 110, 256, 500, 1000]
  // horizontal pixels per inch (dpi)
  const dpi_x = document.getElementById('dpi').offsetWidth
  // horizontal width in inches
  const width = window.screen.width / dpi_x
  // minimum image resolution, images will be at most 20% of screen
  const min_res = width * 0.2 * dpi_x
  // find lowest allowed image resolution
  const resolution = amz_res.find(res => (res >= min_res))
  // default to 1000 pixels
  return resolution || 1000
}

export function secondsToHms (d) {
  d = Number(d)
  var h = Math.floor(d / 3600)
  var m = Math.floor(d % 3600 / 60)
  var s = Math.floor(d % 3600 % 60)
  var msStr = `00${m}`.slice(-2) + ':' + `00${s}`.slice(-2)
  return (h === 0) ? msStr : `00${h}`.slice(-2) + ':' + msStr
}

export function alert (title, text, payload) {
  if (!config.enableAlerts) return Promise.resolve(payload)
  return new Promise(resolve => {
    const div = document.createElement('div')
    div.style.cssText = `background: black; color: white; width: 600px; height: 200px; position; border: 5px solid gray; padding: 20px; text-align: center; position: absolute; left: 310px; top: 200px`
    div.innerHTML = `<h3>${title}</h3><pre>${text}</pre>`
    document.getElementById('root').appendChild(div)
    setTimeout(() => {
      document.getElementById('root').removeChild(div)
      resolve(payload)
    }, 3000)
  })
}

export const noha = str => typeof(str) === 'string' ? str.replace(/^#/, '') : str
export const perha = str => typeof(str) === 'string' ? str.replace(/^#/, '$') : str
export const haper = str => typeof(str) === 'string' ? str.replace(/^\$/, '#') : str

export function isNumeric (n) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}
export function isFunction(f) {
  return (typeof f === 'function')
}

export function formatDuration (ms) {
  let {days, hours, minutes, seconds} = parseMs(ms)
  seconds = addZero(seconds)
  if (days) return `${days}:${addZero(hours)}:${addZero(minutes)}:${seconds}`
  if (hours) return `${hours}:${addZero(minutes)}:${seconds}`
  return `${minutes}:${seconds}`
}

export function uuid () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x3 | 0x8) // eslint-disable-line no-mixed-operators
    return v.toString(16)
  })
}

export function fancyTimeFormat (str) {
  const sec_num = parseInt(str, 10) // don't forget the second param
  if (isNaN(sec_num) || !isNumber(sec_num)) {
    return '--:--'
  }

  let hours = Math.floor(sec_num / 3600)
  let minutes = Math.floor((sec_num - (hours * 3600)) / 60)
  let seconds = sec_num - (hours * 3600) - (minutes * 60)
  if (hours < 10) hours = '0' + hours
  if (minutes < 10) minutes = '0' + minutes
  if (seconds < 10) seconds = '0' + seconds

  let out = [minutes, seconds]
  if (hours.toString() !== '00') out.unshift(hours)

  return out.join(':')
}

export function makeid (len = 5) {
  let text = ''
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  while (text.length < len) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return text
}

export function shallowEqual (objA, objB) {
  if (objA === objB) {
    return true
  }

  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    //console.warn(`[DIRTY] typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null`, objA, objB)
    return false
  }

  var keysA = Object.keys(objA)
  var keysB = Object.keys(objB)

  if (keysA.length !== keysB.length) {
    console.warn(`[DIRTY] keys length difference`, keysA, keysB)

    return false
  }

  // Test for A's keys different from B.
  var bHasOwnProperty = hasOwnProperty.bind(objB)
  for (var i = 0; i < keysA.length; i++) {
    if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
      console.warn(`[DIRTY] Key Diff `, keysA[i], objA[keysA[i]], objB[keysA[i]])
      return false
    }
  }

  return true
}

export function getAPIEndpoint (relativeURI, enclosingPath = '') {
  const path = enclosingPath.replace(/^\/playback\/?/, '/')
  const snippets = path.match(/[a-zA-Z0-9]+/g)
  let parsePath = path
  if (snippets) parsePath = mergePath.apply(null, snippets.slice(0, 3))
  return mergePath(parsePath, relativeURI)
}

export function getTrackEndpoint (relativeURI, enclosingPath = '') {
  const path = enclosingPath.replace(/^\/playback\/?/, '/')
  const endpoint = mergePath(path, relativeURI)
  return endpoint.replace(/\?/, '/?')
}

export function getPointerPath (trackPointer, props) {
  const {music, playable} = props
  const {chunk, indexWithinChunk} = trackPointer
  const totalURL = getLocation(music.pathResolvers[playable.node])
  const path = totalURL.protocol + '//' + totalURL.host + '/playback' + totalURL.pathname
  return mergeChunkWithPathAndQuery(path, chunk, {indexWithinChunk})
}

export function handlePlayable (nextTrackChunk, props) {
  const {node, shuffle, queue, qIdx, location, stations, chunk, direction} = props
  const {dispatch} = store
  let result = getAllowedPlayable(nextTrackChunk, props)
  if (!result) { // no result in current chunk
    let dest
    if (direction === 1 && chunk['nextTrackPointer']) {
      dest = getPointerPath(chunk['nextTrackPointer'], props)
      if (stations.stationPlayback) {
        const _chunk = location.pathname.replace(/^\/playback\/catalog\/stations\/[A-Za-z0-9]+/, '/')
        dispatch(addPrevTrackPointer({indexWithinChunk: nextTrackChunk, chunk: `..${_chunk}`}))
      }
    } else if (direction === 0 && chunk['prevTrackPointer']) {
      dest = getPointerPath(chunk['prevTrackPointer'], props)
    }
    if (dest) handleNewChunk(dest, props)
    // when on last song, dest is undefined
    return
  }
  const {self, track_def, trackChunk} = result
  const track = chunk.trackInstances[nextTrackChunk]
  if (qIdx === queue.length - 1 && shuffle && direction === 1) dispatch(addTrackToQ(track))
  const currentPath = '/playback' + node;
  const trackDef = props.music.nodes[node].trackDefinitions[track_def];
  dispatch(loadTrackDefinition({ node, uri: self, currentPath, trackDef, track_def, playable: location.hash, nextTrackChunk: trackChunk }))
}

export function checkIfExplicit (nextTrackChunk, props) {
  const {music, chunk, location, node} = props
  const nodeObj = music.nodes[node]
  const track_inst = noha(chunk.trackInstances[nextTrackChunk])
  const trackInstance = nodeObj.trackInstances[track_inst]
  if (!trackInstance) return null
  const {self, trackDefinition} = trackInstance
  const track_def = noha(trackDefinition)
  const {isExplicit} = nodeObj.trackDefinitions[track_def]
  const currentPath = location.pathname
  return {isExplicit, self, currentPath, track_def}
}

export function getAllowedPlayable (nextTrackChunk, props) {
  // determines which track to return based on explicit content
  const {playable, shuffle, chunk, direction} = props
  let trackChunk = parseInt(nextTrackChunk)
  let result = checkIfExplicit(trackChunk.toString(), props)
  if (!result) return
  while (`${result.isExplicit}` === 'true' && !playable.allowExplicit) {
    if (shuffle) {
      const lastTrackChunk = chunk['trackInstances'].length - 1
      const randomTrackChunk = randomNextTrack(lastTrackChunk, props)
      trackChunk = parseInt(randomTrackChunk)
    } else trackChunk = (direction === 1) ? trackChunk + 1 : trackChunk - 1
    result = (trackChunk >= 0) ? checkIfExplicit(trackChunk.toString(), props) : null
    if (!result) return
  }
  result.trackChunk = trackChunk.toString()
  return result
}

export function randomNextTrack (lastTrackChunk, props) {
  const {chunk, played} = props
  let instances = chunk.trackInstances.slice(0)
  let nextTrackChunk = random(0, lastTrackChunk).toString()
  instances.splice(nextTrackChunk, 1)
  let track = chunk.trackInstances[nextTrackChunk]
  while (played[track]) {
    nextTrackChunk = random(0, lastTrackChunk).toString()
    track = chunk.trackInstances[nextTrackChunk]
    const index = instances.indexOf(track)
    if (index > -1) instances.splice(index, 1)
    if (!instances.length && played[track]) return null
  }
  return nextTrackChunk
}

export function handleNewChunk (url, props) {
  const {direction, shuffle, qIdx, queue} = props
  const {dispatch} = store
  const {pathname, query, hash} = uparse(url)
  if (qIdx === queue.length - 1 && shuffle && direction === 1) {
    const multiplier = pathname.match(/chunk=[0-9]*/)[0].substr(6)
    const trackNum = parseInt(multiplier) * 10
    const track = '#track_inst_' + trackNum
    dispatch(addTrackToQ(track))
  }
  const {indexWithinChunk} = querystring.parse(query.replace(/^\?+/, ''))
  store.dispatch(setChildNode({
    node: pathname.replace(/^\/?playback\/*/, '/'),
    playable: hash,
    indexWithinChunk
  }))
}

export function handleTrackPlayback (direction) {
  const {dispatch, getState} = store
  const state = getState()
  const {playable, tracks, music, stations, router: {location}} = state
  const chunk = getTrackContainerChunkDescription(state)
  const {shuffle, queue, played, qIdx} = tracks
  const node = playable.node.replace(/\/$/, '')
  const props = {
    node,
    playable,
    tracks,
    music,
    stations,
    chunk,
    location,
    shuffle,
    queue,
    played,
    qIdx,
    direction
  }
  const lastTrackChunk = chunk['trackInstances'].length - 1
  const indexTrackChunk = parseInt(playable.indexWithinChunk)
  if (indexTrackChunk >= 0) {
    let nextTrackChunk
    if (tracks.direction !== direction) dispatch(skipTrack(direction))
    if (direction === 1) { // skip forward
      if (shuffle) {
        if (qIdx === queue.length - 1) {// when at end of queue
          nextTrackChunk = randomNextTrack(lastTrackChunk, props)
        } else {// previously skipped back, songs ahead in queue
          const track_inst = queue[qIdx + 1]
          nextTrackChunk = track_inst.substr(track_inst.length - 1)
          if (!nextTrackChunk) {
            const dest = getPointerPath(chunk['nextTrackPointer'], props)
            handleNewChunk(dest, props)
            dispatch(deltaQIdx(1))
            return
          }
          if (chunk.trackInstances.indexOf(track_inst) === -1) {
            // current track instance is NOT in current chunk
            const tempChunk = Object.assign({}, chunk['nextTrackPointer'])
            tempChunk.indexWithinChunk = nextTrackChunk
            const dest = getPointerPath(tempChunk, props)
            history.push(dest)
            dispatch(deltaQIdx(1))
            return
          }
        }
        handlePlayable(nextTrackChunk, props)
        dispatch(deltaQIdx(1))
      } else {
        if (lastTrackChunk >= (indexTrackChunk + 1)) {
          // currently one or more songs away from end of chunk list
          nextTrackChunk = (indexTrackChunk + 1).toString()
          if (stations.stationPlay) dispatch(setPlayable(node, location.hash, nextTrackChunk))
          else handlePlayable(nextTrackChunk, props)
        } else {
          if (chunk['nextTrackPointer']) { // skipForward called on last song of chunk
            if (stations.stationPlayback) { // currently playing a station
              // create queue of station chunks played
              const _chunk = node.replace(/^\/catalog\/stations\/[A-Za-z0-9]+/, '')
              dispatch(addPrevTrackPointer({
                indexWithinChunk: indexTrackChunk,
                chunk: `..${_chunk}`
              }))
            }
            const dest = getPointerPath(chunk['nextTrackPointer'], props)
            handleNewChunk(dest, props)
          }
        }
      }
    } else if (direction === 0) { // skip backward
      if (shuffle) {
        if (queue && queue.length && qIdx > 0) {
          const track_inst = queue[qIdx - 1]
          nextTrackChunk = track_inst.substr(track_inst.length - 1)
          if (chunk.trackInstances.indexOf(track_inst) !== -1) {
            // check if current track instance is in current chunk
            handlePlayable(nextTrackChunk, props)
          } else {
            // go to previous node
            const tempChunk = Object.assign({}, chunk['prevTrackPointer'])
            tempChunk.indexWithinChunk = nextTrackChunk
            const dest = getPointerPath(tempChunk, props)
            history.push(dest)
          }
          dispatch(deltaQIdx(-1))
        } else return
      } else {
        if (indexTrackChunk >= 1) { // skipping within current chunk
          nextTrackChunk = (indexTrackChunk - 1).toString()
          if (/stations/.test(node)) dispatch(setPlayable(node, location.hash, nextTrackChunk))
          else handlePlayable(nextTrackChunk, props)
        } else { // inspect for previous chunk
          let dest
          if (chunk['prevTrackPointer']) {
            dest = getPointerPath(chunk['prevTrackPointer'], props)
          } else if (stations.stationPlayback && stations['prevTrackPointer'].length) {
            dest = getPointerPath(stations['prevTrackPointer'][stations.batchIdx], props) + '#chunk'
            dispatch(removePrevTrackPointer())
          }
          history.push(dest)
        }
      }
    }
  }
}

export function getRewardForwardStatus () {
  const {getState} = store
  const state = getState()
  const {playable: {indexWithinChunk}, tracks: {shuffle, qIdx}, player: {currentTime}} = state
  const chunk = getTrackContainerChunkDescription(state)
  const lastTrackChunk = chunk['trackInstances'].length - 1
  const indexTrackChunk = parseInt(indexWithinChunk)
  const isLoading = !isNumber(currentTime) || currentTime <= 0

  let canForward = true
  let canReward = true

  if (indexTrackChunk < 0) {
    return [false, false]
  }

  // reward
  if (
    // -> loading ->
    isLoading
    // -> no previous chunk pointer ->
    && !chunk['prevTrackPointer']
    ) {

    // shuffled
    if (
      // -> shuffle first track ->
      qIdx === 0
      // -> shuffled ->
      && shuffle
      ) {
      canReward = false
    }

    if (
      // -> first track on the list ->
      indexTrackChunk === 0
      // -> not shuffled ->
      && !shuffle
      ) {
      canReward = false
    }
  }

  // forward
  if (
    // -> last track on the list ->
    lastTrackChunk === indexTrackChunk
    // -> no next chunk ->
    && !chunk['nextTrackPointer']
    // -> not shuffled ->
    && !shuffle
    ) {
    canForward = false
  }

  return [canReward, canForward]
}

export function handleItemSelection (selected, enclosingPath = '') {
  if (`${selected.isExplicit}` === 'true' && !this.props.allowExplicit) {
    return store.dispatch(addError(ERRORS.explicit_language, true, enclosingPath))
  }

  const { dispatch, getState } = store;
  const state = getState();
  const { tracks, stations } = state;
  const { queue, shuffle } = tracks;
  if (!selected.navigationNodeSummary && selected.playable) {
    if ((queue && queue.length) || shuffle) dispatch(toggleShuffleStatus(false))
    const {itemDescriptions, playables, replace} = this.props
    const item = itemDescriptions[noha(selected.ref)]
    const playable = playables[noha(item.playable)]
    let dest = mergeChunkWithPathAndQuery(['/playback', enclosingPath], playable.naturalTrackPointer.chunk, {indexWithinChunk: playable.naturalTrackPointer.indexWithinChunk})
    if (!dest.startsWith('/playback')) dest = '/playback' + dest
    const stationPlayback = gt(stations, 'stationPlayback')
    const isStation = dest.match(/\/stations\//)
    if (isStation && !stationPlayback) dispatch(toggleStationPlayback(true))
    else if (!isStation && stationPlayback) dispatch(toggleStationPlayback(false))
    const playbackEvent = 'start'
    const idx = (selected.ref.match(/^#track/)) ? 0 : 1
    const reason = reasonCodes[playbackEvent][idx]
    dispatch(setPlaybackEvent(playbackEvent, reason))
    replace(dest)
  } else if (selected.navigationNodeSummary) {
    const {navigationNodeSummaries} = this.props
    const navNode = navigationNodeSummaries[noha(selected.navigationNodeSummary)]
    let listDest = mergePath('/list', enclosingPath, navNode.description)
    if (!listDest.startsWith('/list')) listDest = '/list' + listDest
    this.props.replace(listDest)
  } else {
    console.error('got invalid selected', selected)
  }
}

export function writePlaybackEventReport (event, reason, values) {
  const playbackEventReport = {}
  const state = store.getState();
  const trackInstance = getTrackInstance(state);
  const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
  let transferSpeedBPS = gt(connection, 'downlink', null);
  if (transferSpeedBPS) transferSpeedBPS = Math.round(transferSpeedBPS * 1000000);
  const connectionType = gt(connection, 'type', null);
  const source = (connectionType === 'ethernet') ? 'WIRED' : (connectionType === 'wifi') ? 'WIFI' : 'UNKNOWN';
  if (trackInstance) {
    const {playbackEventCollector} = trackInstance
    const {player: {currentTime}, router: {location}} = state
    const currentPath = (location) ? location.pathname : ''
    const clockTime = new Date()
    playbackEventReport.URI = getAPIEndpoint(playbackEventCollector, currentPath)
    const isDirectedPlay = (userDirected[reason]) ? true : false
    playbackEventReport.body = {
      event: event,
      wallClockTime: clockTime.toISOString(),
      trackPosition: Math.floor(currentTime * 1000) || 0,
      playbackDelay: (values && values.playbackDelay) ? values.playbackDelay : null,
      isDirectedPlay,
      // isShufflePlay,
      // cacheHitStatus,
      source,
      transferSpeedBPS,
      // rebufferCount,
      reason
    }
  }
  console.log(`PlaybackEventReport: sending report with event '${event}' and reason '${reason}'`, playbackEventReport.body)
  return playbackEventReport
}

export function mergePath () {
  if (!arguments.length) return ''
  const args = Array.prototype.slice.call(arguments)
  if (!args && !args.length) return ''
  let joined = uj.call(null, args.filter(a => !!a))
  joined = ru(joined)
  return joined.replace(/.*\/\/[^/]*/, '')
}

export function makeNodeKey (description) {
  return description.replace(/^\.*\//, '/')
}

export function getLocation (href) {

  var match = href.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/)  // eslint-disable-line no-useless-escape
  return match && {
    href: href,
    protocol: match[1],
    host: match[2],
    hostname: match[3],
    port: match[4],
    pathname: match[5],
    search: match[6],
    hash: match[7]
  }
}

export function mergeChunkWithPathAndQuery (path, chunk, query = {}) {
  if (Array.isArray(path)) path = mergePath.apply(null, path)
  const qs = typeof query === 'object' ? query : querystring.parse(query.replace(/^\?+/, ''))
  const destPath = mergePath(path, chunk)
  const href = uparse(destPath)
  const parsed = querystring.parse(href.query.replace(/^\?/,''))
  const combined = Object.assign(qs, parsed)
  const out = `${href.pathname}?${querystring.stringify(combined)}${href.hash}`
  return out
}

export function isNormalInteger (str) {
  var n = Math.floor(Number(str))
  return n !== Infinity && String(n) === str && n >= 0
}

export function getDiff (firstProps, secondProps, name = 'Unknown') {
  console.info('\n DIFF FOR %s \n', name)
  const now = Object.entries(firstProps)
  const added = now.filter(([key, val]) => {
    if (secondProps[key] === undefined) return true
    if (secondProps[key] !== val) {
      console.log(`${key}
          - ${JSON.stringify(val)}
          + ${JSON.stringify(secondProps[key])}`)
    }
    return false
  })
  added.forEach(([key, val]) => console.log(`${key}
          + ${JSON.stringify(val)}`))
}

export function isEquivalent (a, b) {
  return isEqual(a, b)
}

export function retrieveDeviceLanguage() {
  return new Promise((resolve) => {
    return getDeviceLanguage().then(lng => {
      const { code } = getLanguageInstances(lng, 'header')[0]
      store.dispatch(setLanguage(code))
      return resolve()
    })
  })
}

export function getDeviceLanguage () {
  let def = CONFIG.default_language_header

  try {
    const { webOS } = window
    if (webOS && webOS.service && webOS.service.request) {
      return new Promise((resolve) => {
        webOS.service.request('luna://com.webos.settingsservice', {
          method: 'getSystemSettings',
          parameters: {
            keys: ['localeInfo'],
            subscribe: true
          },
          onSuccess: (response) => {
            if (typeof (response.subscribed) != 'undefined') {
              if (!response.subscribed) {
                resolve(def)
                return;
              }
            }

            try {
              // https://webostv.developer.lge.com/api/webos-service-api/settings-service/
              resolve(response.settings.localeInfo.locales.UI || def)
            } catch (_) {
              resolve(def)
            }
          },
          onFailure: () => {
            resolve(def)
          },
        })
      })
    }
  } catch (_) { }

  try {
    // TODO: add get tizen settings
  } catch (_) { }

  return Promise.resolve(def)
}

export function getLanguageInstances (lng, prop = 'code') {
  const languages = [
    {
      code: 'eng',
      header: 'en-US'
    },
    {
      code: 'eng',
      header: 'en-GB'
    },
    {
      code: 'eng',
      header: 'en-CA'
    },
    {
      code: 'deu',
      header: 'de-DE',
    },
    {
      code: 'spa',
      header: 'es-ES',
    },
    {
      code: 'eng',
      header: 'en-GB',
    },
    {
      code: 'fra',
      header: 'fr-FR',
    },
    {
      code: 'ita',
      header: 'it-IT',
    },
    {
      code: 'por',
      header: 'pt-BR',
    },
    {
      code: 'jpn',
      header: 'ja-JP',
    },
  ]

  const instances = languages.filter(l => l[prop] === lng)
  if (instances.length > 0) {
    return instances
  }

  return [{
    code: CONFIG.default_language,
    header: CONFIG.default_language_header,
  }]
}

export function normalizeErrorActions (error, defaultOptions) {
  if (!error) return error
  let options = gt(error, 'options', defaultOptions)
  if (!Array.isArray(options) || options.length === 0) {
    options = defaultOptions
  }

  error.options = options.map((option, i) => {
    if (!option.action) {
      option.action = gt(defaultOptions, `[${i}].action`, 'refresh')
    }

    return option
  })
  return error
}
export function getDeviceDebug() {
  try  {
    const {webOS} = window
    if(webOS && webOS.service && webOS.service.request ) {
      return new Promise((resolve, reject) => {
        webOS.service.request("luna://com.webos.service.tv.systemproperty", {
            method: 'getSystemInfo',
            parameters: {keys: ["boardType", "firmwareVersion","modelName","sdkVersion"]},
            onSuccess: resolve,
            onFailure: reject
        })
      })
    } else return Promise.resolve({
      device: 'none'
    })
  } catch(e) {
    console.error("Error getting device debug",e)
  }
}