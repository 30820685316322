import React, { Component } from 'react'
import Catalog from './Catalog'
import { connect } from 'react-redux'
import { loadChildNode } from '../../store/modules/music'
import { updateAllNodes } from '../../store/modules/cache'
import { replace, back } from '../../store/modules/nav'
import { handleItemSelection, makeNodeKey, noha } from '../../lib/utils'
import { updateMenuState } from '../../lib/reactv-redux/ReacTVReduxReducer'
import {
  makeGetCatalogData,
  getPlayableSelector,
  getItemDescriptionsSelectors,
  getNavigationNodeSummariesSelector
} from '../../lib/selectors/node_selectors'
import PageLoading from '../../components/PageLoading'
import {calculateOffsetHeight} from '../../lib/reactv-redux/SlotMenuRedux'

const getPath = (pathname,page) => {
  let page_p = '';
  let pathname_p = pathname.replace(/^\/list\/*/, '/');
  if (page) {
    pathname_p = pathname_p.replace(/\/?page=[0-9]*\/?/,'')
    page_p = page.replace(/\.\./,'');
    if (page_p.charAt(0) !== '/') page_p = '/' + page_p
  }
  let key = pathname_p + page_p;
  return key
}

const makeMapStateToProps = () => {
  let getCatalogData, getNextCatalogData;
  try {
     getCatalogData = makeGetCatalogData();
     getNextCatalogData = makeGetCatalogData();
  } catch(e) {
    console.warn(e)
    console.info(e.stack)
  }
  const mapStateToProps = (state,ownProps) => {
    try {
    const { pathname, search, hash } = ownProps.location;
    const menuid = `catalogmenu:${pathname}${search}${hash}`;
    const nodes = state.music.nodes;
    const currentNode = (state.cache[menuid]) ? state.cache[menuid].currentNode : null;
    const nextNode = (state.cache[menuid]) ? state.cache[menuid].nextNode : null;
    const highlightedTrack = state.menus[menuid];
    const catalog = getCatalogData(state,currentNode);
    const currentFocus = (catalog && highlightedTrack && highlightedTrack.index < catalog.items.length) ? currentNode : nextNode;
    return ({
      nodes,
      menuid,
      currentNode,
      nextNode,
      currentFocus,
      cache: state.cache,
      allowExplicit: state.playable.allowExplicit,
      showModal: state.modal.showModal,
      highlightedTrack,
      catalog,
      stack: state.nav.stack,
      nextCatalog: (nodes[nextNode]) ? getNextCatalogData(state,nextNode) : null,
      itemDescriptions: getItemDescriptionsSelectors(state, currentFocus),
      playables: getPlayableSelector(state, currentFocus),
      navigationNodeSummaries: getNavigationNodeSummariesSelector(state, currentFocus)
    })
  } catch(e) {
    console.info(e)
    console.info(e.stack)
  }
  }
  return mapStateToProps
}

const mapDispatchToProps = {loadChildNode, replace, back, updateMenuState, updateAllNodes}

class CatalogContainer extends Component {
  constructor (p) {
    super(p)
    this.state = { firstNitems: 100 };
    this.handleSelection = (dest) => {
      try {
      const { currentFocus } = this.props;
      handleItemSelection.call(this, dest, currentFocus.split(/\/#/)[0])
      } catch(e) {
        console.error(e)
        console.trace()
      }
    }
    this.firstNitems = this.state.firstNitems;
    this.update_state = false;
  }
  componentDidMount () {
    this.loadIfNeeded()
    const { menuid, currentNode, updateAllNodes, location, cache } = this.props;
    let path = getPath(location.pathname.replace(/^\/(list|music)\/*/, '/')) // sanitize path
    if (location.search) {
      path += decodeURIComponent(`/${location.search}`)
    }

    if (path !== '/' && (!currentNode || !cache[menuid] || path !== currentNode)) {
      updateAllNodes({ menuid, currentNode: path, nextNode: null })
    }
    
    this.pageBackgroundLoading()

    console.log('Screen: catalog')
  }

  componentWillUnmount () {
    clearInterval(this.emit)
  }

  calculateStyle (currentState, newState, ref) {
    if (newState.index > currentState.index) {
      return {transform: `translateY(-${calculateOffsetHeight(ref, newState.index, newState.slotIndex)}px)`}
    } else if (newState.index < currentState.index) {
      return {transform: `translateY(-${calculateOffsetHeight(ref, newState.index, newState.slotIndex)}px)`}
    } else {
      return null
    }
  }

  getNewStyle ({highlightedTrack: {index: oldIndex, slotIndex: oldSlotIndex, style}},{highlightedTrack: {index, slotIndex}}, extendBy) {
    const newStyle = {index, slotIndex}
    const calStyle = this.calculateStyle({
      index: oldIndex,
      slotIndex: oldSlotIndex
    }, {index: index + extendBy, slotIndex}, this._ref)
    if (calStyle !== null) {
      const updatedStyle = Object.assign({}, style || {}, calStyle)
      newStyle.style = updatedStyle
    }
    if (calStyle === null) console.info('newStyle: ', newStyle)

    return newStyle
  }

  static getDerivedStateFromProps(props, state) {
    // to build local catalog state based on redux store
    const { catalog, nextCatalog, highlightedTrack } = props;
    const dataLength = state.firstNitems; // this will be ~100
    if (catalog && (!state.catalog || state.catalog.prevPage !== catalog.prevPage || (state.catalog && state.catalog.items.length < catalog.items.length))) {
      let newData = catalog.itemsData.slice(0,dataLength);
      if (nextCatalog && highlightedTrack) {
        newData = newData.concat(nextCatalog.itemsData.slice(0, dataLength)) // should be nextNode data concacted
      }
      const newState = Object.assign({}, catalog, {itemsData: newData})
      return { catalog: newState }
    }
    return null
  }

  growCatalog(menu) {
    const { menuid, catalog, nextCatalog, updateMenuState } = this.props;
    const firstItems = catalog.itemsData.slice(0,this.firstNitems);
    const secondItems = nextCatalog.itemsData.slice(0,this.firstNitems);
    const itemsData = firstItems.concat(secondItems);
    const menuState = Object.assign({}, menu, {
      maxSlot: firstItems.length + secondItems.length - 1, // adjust for more data
      max: firstItems.length + secondItems.length - 1, // adjust for more data
    })
    updateMenuState(menuid,menuState)
    this.setState({ catalog: Object.assign({}, catalog, { itemsData }) })
  }

  componentDidUpdate(prevProps) {
    const { menuid, highlightedTrack, catalog, nextCatalog, updateMenuState, updateAllNodes, currentNode, nextNode, location: { pathname }, loadChildNode, nodes } = this.props;
    if (catalog) {
      this.loadIfNeeded()
      let menuState; // presented data based on state and list index
      const dataLength = catalog.items.length; // this will be ~100
      const prevPage = (catalog.prevPage) ? getPath(pathname,catalog.prevPage) : null;
      const nextPage = (catalog.nextPage) ? getPath(pathname,catalog.nextPage) : null;

      if (nextCatalog && (currentNode !== prevProps.currentNode || nextNode !== prevProps.nextNode)) {
        let menu = {};
        if (this._newStyle) {
          const currIdx = highlightedTrack.index;
          const displacement = catalog.itemsData.slice(0,this.firstNitems).length;
          const newStyle = this.getNewStyle(prevProps, this.props, displacement)
          menu = {
            index: currIdx + displacement, // adjust for prependata shifting current item
            slotIndex: highlightedTrack.slotIndex, // no change
            style: newStyle.style  // adjust for prependata shifting current item
          }
        }
        this.growCatalog(menu)
        this._newStyle = false;
      }

      if (this.state.catalog && prevProps.highlightedTrack && highlightedTrack && (prevPage || nextPage)) {
        // only modify list if there is a prevPage or nextPage & while scrolling
        const listLength = this.state.catalog.itemsData.length;

        // indices as user scrolls
        const prevIdx = prevProps.highlightedTrack.index;
        const currIdx = highlightedTrack.index;

        // when to make adjustments to the infinite list
        const loadPrevAt = Math.floor(this.firstNitems * 0.25);
        const rmAllAt = Math.floor(this.firstNitems * 0.50);
        const loadNextAt = Math.floor(this.firstNitems * 0.75);

        if (currIdx === prevIdx - 1) { // scrolling up
          if (currIdx <= loadPrevAt && prevPage && !nextCatalog && listLength <= dataLength) { // load prev section
            if (!nodes[prevPage]) loadChildNode(prevPage)
            clearInterval(this._checkIfLoaded)
            this._checkIfLoaded = setInterval(() => {
              if (this.props.nodes[prevPage] && this.props.currentNode !== prevPage) {
                this._newStyle = true;
                updateAllNodes({ menuid, currentNode: prevPage, nextNode: currentNode })
                // update redux store with correct index after prepending data to list:
                clearInterval(this._checkIfLoaded)
              }
            }, 100)
          } else if (currIdx <= rmAllAt && listLength > this.firstNitems) { // remove next section
            updateAllNodes({ menuid, currentNode, nextNode: null })
            menuState = {
              maxSlot: dataLength-1, // adjust for less data
              max: dataLength-1, // adjust for less data
            }
            updateMenuState(menuid,menuState)
          }
        } else if (currIdx === prevIdx + 1) { // going down
          if (currIdx >= this.firstNitems + rmAllAt && nextPage) { // remove prev section
            const cleanNode = makeNodeKey(nextPage)
            updateAllNodes({ menuid, currentNode: cleanNode, nextNode: null })
            const newStyle = this.getNewStyle(prevProps, this.props, -dataLength)
            menuState = {
              index: currIdx - dataLength, // adjust for cropping data from beginning, shifting current item
              slotIndex: highlightedTrack.slotIndex, // no change
              maxSlot: nextCatalog.items.length-1, // adjust for less data
              max: nextCatalog.items.length-1, // adjust for less data
              style: newStyle.style  // adjust for prependata shifting current item
            }
            updateMenuState(menuid,menuState)
          } else if (listLength <= dataLength && currIdx >= loadNextAt && nextPage && !nextCatalog) { // load next section
            if (!nodes[nextPage]) loadChildNode(nextPage)
            clearInterval(this._checkIfLoaded)
            this._checkIfLoaded = setInterval(() => {
              if (this.props.nodes[nextPage] && this.props.nextNode !== nextPage) {
                updateAllNodes({ menuid, currentNode, nextNode: nextPage })
                clearInterval(this._checkIfLoaded)
              }
            }, 100)
          }
        }
      }
    }
  }

  loadIfNeeded(force) {
    const { currentNode, nodes, catalog, updateMenuState, menuid, highlightedTrack } = this.props;
    if (force || (currentNode && nodes[currentNode] && !this.state.itemsHaveBeenFetched)) {
      const node = nodes[currentNode];
      const _desc = noha(node.result);
      const _summary = noha(node.navigationNodeDescriptions[_desc].summary);
      if (force || (catalog && node.navigationNodeSummaries[_summary].numItemsOfInterest > catalog.items.length)) {
        this.props.loadChildNode(currentNode)
        const maxLength = 100;
        const menuState = {
          maxSlot: maxLength, // adjust for more data
          max: maxLength, // adjust for more data
        }
        if (highlightedTrack && highlightedTrack.maxSlot < maxLength) updateMenuState(menuid,menuState)
      }
      this.setState({ itemsHaveBeenFetched: true, fetchedBackground: !this.state.fetchedBackground  })
    }
  }

  pageBackgroundLoading() {
    clearInterval(this.emit)
    this.emit = setInterval(() => {
      this.loadIfNeeded(true)
    }, 30 * 1000) // load data evey 30 sec
  }

  render () {
    if (typeof(this.state.catalog) === 'object') {
      const { highlightedTrack, showModal, location, allowExplicit } = this.props;
      const { pathname, search, hash } = location;
      const { itemsData, title } = this.state.catalog;
      const currentIndex = (highlightedTrack && itemsData.length) ? Math.min(highlightedTrack.index, itemsData.length - 1) : null;
      const thumbnail = (currentIndex || currentIndex === 0) ? itemsData[currentIndex].image : null;
      const referer = (r) => { this._ref = r }
      return <Catalog
        itemsData={itemsData}
        title={title}
        showModal={showModal}
        passRef={referer}
        thumbnail={thumbnail}
        allowExplicit={allowExplicit}
        kid={pathname + search + hash}
        onSelect={this.handleSelection.bind(this)} />
    } else {
      return <PageLoading/>
    }
  }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(CatalogContainer)
