import Cookie from 'js-cookie'
import $badger from '../../lib/badger'

export const OPEN_MODAL = 'MODAL/OPEN_MODAL'
export const CLOSE_MODAL = 'MODAL/CLOSE_MODAL'
export const TOGGLE_MODAL = 'MODAL/TOGGLE_MODAL'

export const TOGGLE_SKIP_ON_ERROR = 'MODAL/TOGGLE_SKIP_ON_ERROR'
export const ADD_GENERAL_ERROR_REPORT = 'MODAL/ADD_GENERAL_ERROR_REPORT'
export const ADD_ERROR = 'MODAL/ADD_ERROR'
export const REMOVE_ERROR = 'MODAL/REMOVE_ERROR'
export const HANDLE_ERROR = 'MODAL/HANDLE_ERROR'

export function toggleSkipOnError() {
  return {
    type: TOGGLE_SKIP_ON_ERROR
  }
}

export function addGeneralErrorReport(data) {
  return {
    type: ADD_GENERAL_ERROR_REPORT,
    ...data
  }
}

export function addError({ ref, autoSkipOnError, report, source, continuePlayback }, badgerLog, path) {
  if (process.env.REACT_APP_UPSELL_TITLE) {
    // -> upsell_banner -> replace
    if ((report.brief || '').toLowerCase().includes('unlimited') ) {
      report.brief = process.env.REACT_APP_UPSELL_TITLE
      report.explanation = process.env.REACT_APP_UPSELL_MESSAGE
      report.options = [{ action: path ? null : 'back', label: 'OK', resume: false}]
    }
  }

  // -> Update Your Account -> replace
  if ((report.brief || '').toLowerCase().includes('update your account') ) {
    if (!Array.isArray(report.options) || report.options.length === 0) {
      report.options = [
        {label: 'Yes', resume: false, action: 'www', uri: 'https://music.amazon.com'},
        {label: 'No', resume: false, action: 'logout'},
      ]
    }

    report.options = report.options.map(option => {
      if ((option.label || '').toLowerCase().trim() === 'no') {
        option.action = 'logout'
      }

      return option
    })
  }

  if (badgerLog && report && (report.explanation || report.brief)) {
    $badger.errorMetricsHandler(report.explanation || report.brief, true, null, {
      options: report.options.length
    })
  }
  // set session cookie
  Cookie.set(ref || 'unknown_modal_ref', Date.now())
  return {
    type: ADD_ERROR,
    ref, autoSkipOnError, report, source, continuePlayback, path
  }
}

export function removeError() {
  return {
    type: REMOVE_ERROR
  }
}

export function handleError({option, source}) {
  return {
    type: HANDLE_ERROR,
    option, source
  }
}

export function openModal() {
  return {
    type: OPEN_MODAL
  }
}

export function closeModal() {
  return {
    type: CLOSE_MODAL
  }
}

const initialState = {
  path: '',
  showModal: false,
  error: null,
  autoSkipOnError: false,
  source: null,
  generalErrorReports: null
}

export default function modalReducer(state = initialState, action) {
  switch(action.type) {
    case TOGGLE_SKIP_ON_ERROR:
      return Object.assign({}, state, { autoSkipOnError: !state.autoSkipOnError })
    case ADD_GENERAL_ERROR_REPORT:
      return Object.assign({}, state, { error: action.ref, generalErrorReports: action.report, source: action.source, path: action.path })
    case REMOVE_ERROR:
      return Object.assign({}, initialState)
    case TOGGLE_MODAL:
      return Object.assign({}, state, {showModal: !state.showModal})
    case OPEN_MODAL:
      return Object.assign({}, state, {showModal: true})
    case CLOSE_MODAL:
      return Object.assign({}, state, {showModal: false, error: null, source: null})
    default:
      return state
  }
}
