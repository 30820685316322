import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import uj from 'url-join'
import cx from 'classnames'
import { authWrapper, userIsNotAuthenticated } from '../../services/auth'
import { retrieveDeviceLanguage } from '../../lib/utils'
import Home from '../Home'
import Linking from '../Linking'
import Catalog from '../Catalog'
import Playback from '../Playback'
import config from '../../config'
import Search from '../Search'
import AmazonMusicLogo from '../../assets/images/AmazonMusicLoadingLogoWhite.svg'
import PageLoading from '../../components/PageLoading/PageLoading'
import css from '../../pages/Linking/Linking.scss'

const Routes = () => {
  const [loading, setLoading] = React.useState(true)
  React.useEffect(() => {
    const init = async () => {
      await retrieveDeviceLanguage()
      setLoading(false)
    }

    setTimeout(init, 0)
  }, [])

  if (loading) {
    return (
      <div className={cx(css.Linking, 'Page')}>
        <img alt='amazon-music-logo' className='amazon-logo amazon-logo--centered' src={AmazonMusicLogo}/>
        <PageLoading className='disableMargin'/>
      </div>
    )
  }

  return (
    <>
      <Route exact path="/" render={() => (<Redirect to={uj('/music',config.music.browse_node)} />)} />
      {/*<Route exact path="/music" component={authWrapper(NavigationNode)} />*/}
      <Route path="/music/:section?*" component={authWrapper(Home)} />
      <Route path="/list/:node*" component={authWrapper(Catalog)} />
      <Route path="/playback/:track*" component={authWrapper(Playback)} />
      <Route path="/widescreen_search" component={authWrapper(Search)} />
      <Route exact path="/linking" component={userIsNotAuthenticated(Linking)} />
      <Route exact path="/settings" component={authWrapper(Home)} />
    </>
  )
}

export default Routes
