import 'react-app-polyfill/ie9'
import './lib/polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './pages/App'
import registerServiceWorker from './registerServiceWorker';
import { pollToken } from './lib/utils';
import ErrorReporter from './lib/ErrorReporter';
import './i18n'

const bugsnagClient = ErrorReporter.init();
const ErrorBoundary = bugsnagClient.getPlugin('react');

try {
  const lt = (typeof localStorage !== 'undefined' && localStorage) ? localStorage : {}
  lt.debug = 'request:*, app:*'
  console.info('set debug to axios')
} catch (e) {
  console.error('error setting debug', e.message)
}

// request new access token at intervals
const refreshPoll = 55 * 60 * 1000 // 55 minutes
setInterval(() => { pollToken() }, refreshPoll)

console.log('Rendering the App')

ReactDOM.render(
<ErrorBoundary>
  <App />
</ErrorBoundary>,
document.getElementById('root')
);
registerServiceWorker();

// import Map from 'es6-map/polyfill'
// import Assign from 'es6-object-assign'
// Assign.polyfill()
// window.Map = Map
// console.info('Object.assign', Object.assign)
