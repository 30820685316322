import React from 'react'
import cx from 'classnames'
import { Trans } from 'react-i18next'
import AmazonMusicLogo from '../../assets/images/AmazonMusicLoadingLogoWhite.svg'
import PageLoading from '../../components/PageLoading/PageLoading'

const Link = ({ url }) => {
  const protocolExemptUrl = url.replace(/(^\w+:|^)\/\//, '')
  return <a href={url} target="_blank" rel="noopener noreferrer">{protocolExemptUrl}</a>
}

const Linking = ({user_code, verification_uri, className}) => {
  if (!user_code) {
    return (
      <div className={cx(className, 'Page')}>
        <img alt='amazon-music-logo' className='amazon-logo amazon-logo--centered' src={AmazonMusicLogo}/>
        <PageLoading className='disableMargin'/>
      </div>
    )
  }
  return (
    <div className={cx(className, 'Page')}>
      <img alt='amazon-music-logo' className='amazon-logo' src={AmazonMusicLogo}/>
      <div className='headline'><Trans i18nKey='with-id[.]linking-healine'>ACTIVATE</Trans></div>
      <label className='text-info text-link'>
        <Trans i18nKey='with-id[.]linking-link' components={[<Link url={verification_uri || 'https://amazon.com/code'} />]}>{'1. Visit <0 /> on your computer or mobile device'}</Trans>
      </label>
      <label className='text-info'>
        <Trans  i18nKey='with-id[.]linking-info'>2. Enter the following code</Trans>
      </label>
      <div className='code'>
        <label>{user_code}</label>
      </div>
    </div>
  )
}
export default Linking
