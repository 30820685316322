import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import i18n from 'i18next'
import franc from 'franc-min'
import gt from 'lodash/get'
import { client as authClient } from '../../services/auth'
import API, { client as apiClient } from '../../services/service'
import { SET_AUTH_DATA } from '../modules/auth'
import { SET_LNG, SET_HEADER } from '../modules/lng'
import { noha, getLanguageInstances } from '../../lib/utils'
import config from '../../config'

function * setLanguage () {
  let code = config.default_language
  try {
    const QUERY = 'adelaidgoldeneggs'
    const { data } = yield call(API.loadNavigationNode, `/widescreen_search/?keywords=${QUERY}`, false)
    const node = gt(data, `navigationNodeDescriptions.${noha(data.result)}.items[0]`)
    const label = gt(data, `itemDescriptions.${noha(node)}.itemLabel`)
    const text = label.replace(QUERY, '')
    const guess = franc(text)

    switch (guess) {
      // Spanish is wrongly detected as Portuguese
      case 'por':
        code = text.startsWith('Lo sentimos') ? 'spa' : guess
        break
      // -> unknown language -> eng
      case 'und':
        break
      default:
        code = guess
        break
    }

  } catch (_) { }

  yield put({ type: SET_LNG, code })
}

function* setLanguageHeader ({ code }) {
  let { header } = getLanguageInstances(code)[0]
  if (config.client.name === 'RO' && code === 'eng') {
    header = config.default_language_header
  }

  yield put({ type: SET_HEADER, header })
}

function * changeLanguage ({ code }) {
  yield i18n.changeLanguage(code)
}

function * changeClientHeader ({ header }) {
  yield authClient.defaults.headers['Accept-Language'] = header
  yield apiClient.defaults.headers['Accept-Language'] = header
}

function * lngSaga () {
  yield takeEvery(SET_LNG, setLanguageHeader)
  yield takeEvery(SET_LNG, changeLanguage)
  yield takeEvery(SET_HEADER, changeClientHeader)
  yield takeLatest(SET_AUTH_DATA, setLanguage)
}

export default lngSaga
