import React, { Component } from 'react'
import ReactModal from 'react-modal'
import { connect } from 'react-redux'
import Cookie from 'js-cookie'
import gt from 'lodash/get'
// import i18n from 'i18next'
import './Settings.scss'
import ERRORS from '../../errors/messages';
import { addError } from '../../store/modules/modal'
import { toggleSettings } from '../../store/modules/nav'
import { playerCurrentSrc } from '../../store/modules/player'
import { toggleAllowExplicit } from '../../store/modules/playable'
import { getTrackInstance } from '../../pages/Playback/selectors'
import Settings from './Settings'
import config from '../../config'

const mapStateToProps = (state) => ({
  trackInstance: getTrackInstance(state),
  allowExplicit: state.playable.allowExplicit,
  showSettings: state.nav.showSettings
})

const mapDispatchToProps = { addError, toggleSettings, toggleAllowExplicit, playerCurrentSrc }

class SettingsContainer extends Component {
  constructor() {
    super();
    this.handleUnlinkButton = this.handleUnlinkButton.bind(this);
    this.handleExplicitButton = this.handleExplicitButton.bind(this);
    this.handleExitButton = this.handleExitButton.bind(this);
  }

  handleUnlinkButton() {
    Cookie.remove('amzn_music_auth')
    if (document.cookie.indexOf("amzn_music_auth=") >= 0) {
      this.props.addError(ERRORS.unlink, true)
    } else {
      // i18n.changeLanguage(config.default_language)
      window.location.href = '/'
    }
  }

  handleExplicitButton() {
    const { toggleAllowExplicit, allowExplicit, playerCurrentSrc, trackInstance } = this.props;
    // is current asset an explicit song
    const isExplicit = gt(trackInstance, 'trackDefinitionData.isExplicit', 'false')
    // note: next allowExplicit flag will be false false
    if (`${allowExplicit}` === 'true' && `${isExplicit}` === 'true') playerCurrentSrc(null)
    toggleAllowExplicit()
  }

  handleExitButton() {
    this.props.toggleSettings()
  }

  componentWillMount() {
    ReactModal.setAppElement('body');
  }

  render () {
    if (this.props.showSettings) {
      return (
        <Settings
          focused={true}
          menuid='Settings'
          onFocusItem='explicit'
          handleUnlinkButton={this.handleUnlinkButton}
          handleExplicitButton={this.handleExplicitButton}
          handleExitButton={this.handleExitButton}
          allowExplicit={this.props.allowExplicit}/>
      )
    }
    return null
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer)
