
import axios from 'axios'
import debug from 'debug'
import axLogger from 'axios-debug-log'
import up from 'url-parse'
// import i18n from 'i18next'

import config from '../config'
import Cookie from 'js-cookie';

export const client = axios.create({
  // `baseURL` might override by method
  baseURL: config.music.base_url,
  headers: {
    'Accept-Language': config.default_language_header,
    'X-Amz-Music-Images-Accept': '*/*',
    'X-Amz-Music-Images-Shrink': 'navigation-node=350x350, item=350x350, track-container=350x350, track=350x350, *=350x350'
  }
})
const requestLogger = debug('request:axios')
axLogger.addLogger(client, requestLogger)

const API = {
  cache: {},

  ACCESS_TOKEN: null,
  loggedIn: () => {
    return !!API.ACCESS_TOKEN
  },

  request: (origin = '', method, body, options) => {
    let path = origin
    if (method.toLowerCase() === 'get') {
      const parsed = up(path.replace(/^\/?/, '/'))
      path = `${parsed.pathname.replace(/\/?$/, '')}/${parsed.query}${parsed.hash}`
    }
    let baseURL = method.toLowerCase() === 'post' ? config.music.post_base_url : config.music.base_url
    let args = (body) ? [path, body] : [path];
    let transformRequest = [(data, headers) => {
      if (method.toLowerCase() === 'post') {
        headers['X-Amz-Music-Client-Name'] = config.client.name
      } else {
        delete headers['X-Amz-Music-Client-Name']
      }

      return JSON.stringify(data)
    }]
    args = [...args, options = {...options, baseURL, transformRequest}]
    console.info(`API.request\n%c${origin}`, 'color:aqua', { ...args,  method, origin })
    return client[method.toLowerCase()](...args)
      .then(response => response)
      .catch(({ message, response: { data, status, statusText } }) => {
        console.info(`API.request catch\n%c${origin}\n${message}`, 'color:deeppink', { data, status, statusText })
        /**
         * @param {(object|null)} data
         * @param {integer} status
         * @param {string} statusText
         */
        throw ({ data, status, statusText })
      })
  },

  loadNavigationNode: (path = '/', retry) => {
    const timeout = retry ? 15000 : 10000;
    return API.request(path, 'get', null, { timeout })
  },

  setToken: (token) => {
    console.log('Set API header and API.ACCESS_TOKEN to ACCESS_TOKEN')
    // get it now, track changes
    API.ACCESS_TOKEN = token
    client.defaults.headers.common['Authorization'] = `Bearer ${API.ACCESS_TOKEN}`
  },

  deleteToken: () => {
    API.ACCESS_TOKEN = null
    Cookie.remove('amzn_music_auth')
    // i18n.changeLanguage(config.default_language)
    window.location.href = '/'
  }
}

export default API
