import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects'
import Cookie from 'js-cookie'
import gt from 'lodash/get'
import API from '../../services/service'
import config from '../../config'
import store from '..'
import {
  addChildNode, loadChildNode, LOAD_CHILD_NODE
} from '../modules/music'
import { TRACK_ACCESS_TOKEN, SET_AUTH_DATA } from '../modules/auth'
import { setUpsaleMessage } from '../modules/upsale'
import { addError } from '../modules/modal'
import $badger from '../../lib/badger'
import ERRORS from '../../errors/messages'

const getData = state => state.music.nodes
const getError = state => state.modal.error
// Dealing with some circular dependencies here.

function * loadNavigationNode (action) {
  const { path, retry } = action;
  try {
    const payload = yield call(API.loadNavigationNode, path, retry)
    if (payload.response && payload.response.status === 409) throw payload.response;
    const {responseURL = ""} = payload.request
    const responsePath = responseURL.replace(config.music.endpoint, '')
    const node = Object.assign({}, payload.data);
    yield put(addChildNode(node, path, responsePath))
  } catch (e) {
    // authentication error
    if (e.status === 401 || e.status === 403) return API.deleteToken()

    // retry failed call [once]
    if (!retry) {
      return store.dispatch(loadChildNode(path, { retry: true }))
    }

    let report
    // validate e.data contains `generalErrorReports` object
    const { generalErrorReports, result } = gt(e, 'data', {})

    if (typeof result === 'string') {
      // sample data { result: '#error_report', generalErrorReports: { error_report: { terse, brief, explanation, options } } }
      report = gt(generalErrorReports, result.replace(/#/g, ''), false)

      // update store music
      if (report) {
        // update store upsell message
        if (result === '#upsell_banner') {
          yield put(setUpsaleMessage(report))
        }

        yield put(addChildNode({ generalErrorReports, result }, path))
      }
    }

    const displayedAt = Cookie.get(result)
    if (result === '#upsell_banner' && displayedAt) {
      // -> #upsell_banner cookie -> return
      return
    }

    // Error modal and report
    const currentError = yield select(getError)
    // report this and skip modal
    if (currentError !== null) {
      return $badger.errorMetricsHandler(`PayLoadError action:[${JSON.stringify(action)}] err:[${JSON.stringify(e)}]`, true, null, { action, err: e })
    }

    // display modal; either from the call response or default timeout
    console.info('Got here Loading timeout music', e)
    const errorMessage = result === '#upsell_banner' ? ERRORS.upsell_banner :  ERRORS.loading_timeout
    yield put(addError(errorMessage(report || { terse: `${e.message} for path ${path}` }, result), true, path))
  }
}


function * trackAccessToken (action) {
  try {
    const { payload } = action;
    yield call(API.setToken, payload.access_token)
    yield put({type: SET_AUTH_DATA, payload})

  } catch (e) {
    console.error('Issue tracking access token ', e)
  }
}

function * registerPathChange (action) {
  console.info(`${action.type}: ${action.payload.location.pathname} - auth'd:`, API.loggedIn())
  try {
    const { pathname, search } = action.payload.location
    if (API.loggedIn() && /^\/?list(\/|$)/.test(pathname)) {
      let key = action.payload.location.pathname.replace(/^\/list\/*/, '/')
      key = key.trim() === '' ? '/' : key
      if (search) key += `/${search}`;
      const data = yield select(getData)
      if (!data[key]) {
        yield put(loadChildNode(key))
      }
      // TODO: Handle loading data here....
    } else if (API.loggedIn() && /^\/?music(\/|$)/.test(pathname)) { // TODO: Need a mechanism for managing these
      let path = pathname.replace(/(^\/?music(\/|$))/, '/')
      if (!path || path.trim() === '') path = config.music.browse_node
      const nodes = store.getState().music.nodes;
      if (!nodes[path]) yield put(loadChildNode(path))
    }
    else if (API.loggedIn() && /^\/?settings(\/|$)/.test(pathname)) { // TODO: Need a mechanism for managing these
      action.payload.location.pathname = '/music' + config.music.browse_node
      yield put(loadChildNode(config.music.browse_node))
    }
  } catch (e) {
    console.warn(`Error registering for path change ${e.message}`, e)
  }
}

function * musicSaga () {
  yield takeEvery(LOAD_CHILD_NODE, loadNavigationNode)
  yield takeLatest(TRACK_ACCESS_TOKEN, trackAccessToken)
  yield takeLatest('@@router/LOCATION_CHANGE', registerPathChange)
}

export default musicSaga
