import config from '../../config'

export const SET_LNG = 'LNG/SET_LNG'
export const SET_HEADER = 'LNG/SET_HEADER'

const initialState = {
  code: config.default_language,
  header: config.default_language_header,
}

export const setLanguage = (code) => {
  return { type: SET_LNG, code }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LNG:
      return Object.assign({}, state, { code: action.code })
    case SET_HEADER:
      return Object.assign({}, state, { header: action.header })
    default:
      return state
  }
}
