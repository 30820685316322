import React, { Component } from 'react'
import Linking from './Linking'
import { connect } from 'react-redux'
import { removeError } from '../../store/modules/modal'
import css from './Linking.scss'

const mapStateToProps = ({ linking: { user_code, verification_uri }}) => ({
    user_code, verification_uri
  }
)

const mapDispatchToProps = { removeError }
class LinkingContainer extends Component {
  componentDidMount() {
    console.log('Screen: linking')
  }
  componentWillUnmount() {
    this.props.removeError()
  }
  render () {
    const { verification_uri, user_code } = this.props
    return (
      <Linking
        className={css.Linking}
        user_code={user_code}
        verification_uri={verification_uri} />
    )
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(LinkingContainer)
