import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from '../../store'
import Routes from '../Routes'
import Player from '../../components/Player'
import './App.scss'
import Settings from '../../components/Settings'
import Modal from '../../components/Modal'
import { back } from '../../store/modules/nav'
import KeyEvents from '../../lib/reactv-navigation/KeyEvents'

const Keys = new KeyEvents()

class App extends Component {
  constructor(props) {
    super(props)
    this.globalHistoryBack = this.globalHistoryBack.bind(this)
  }

  componentDidMount() {
    this._unsubBack = Keys.subscribeTo('Back', this.globalHistoryBack)
    this._unsubReturn = Keys.subscribeTo('Return', this.globalHistoryBack)
    console.log('Intial the app')
  }

  componentWillUnmount() {
    if (this._unsubBack) this._unsubBack.unsubscribe()
    if (this._unsubReturn) this._unsubReturn.unsubscribe()
  }

  globalHistoryBack() {
    try {
      if (store.getState().nav.showSettings) {
        return
      }
    } catch (_) { }
    back()(store.dispatch, store.getState)
  }

  render() {
    return (
      <Provider store={store}>
        <>
          <ConnectedRouter history={history}>
            <Routes />
          </ConnectedRouter>
          <Settings />
          <Modal />
          <Player />
        </>
      </Provider>
    )
  }
}

export default App
