import { call, put, takeLatest, select } from 'redux-saga/effects'
import gt from 'lodash/get'
import API from '../../services/service'
import config from '../../config'
import { ADD_CHILD_NODE } from '../modules/music'
import { searchResults, loadSearchNode, LOAD_SEARCH_NODE } from '../modules/search'
import ERRORS from '../../errors/messages'
import { addError } from '../modules/modal'
import store from '..'
import $badger from '../../lib/badger'

const getError = state => state.modal.error

function * loadSearchResultsNode (action) {
  const { path, retry } = action;
  try {
    const payload = yield call(API.loadNavigationNode, path, retry) // pull search results from API
    yield put({type: ADD_CHILD_NODE, node: payload.data, path: '/widescreen_search', resolvePath: config.music.base_url + path})
    yield put(searchResults(payload)) // adds search results to state.search.results.data
  } catch (e) {
    console.warn(`Error loading search result for path ${path}`, e)
    if(e.status === 401 || e.status === 403) return API.deleteToken()
    if (!retry) {
      // NOTE: maybe check ?keyword= still has a value?
      return store.dispatch(loadSearchNode(path, { retry: true }))
    }

    let report
    // validate e.data contains `generalErrorReports` object
    const { generalErrorReports, result } = gt(e, 'data', {})

    if (typeof result === 'string') {
      // sample data { result: '#error_report', generalErrorReports: { error_report: { terse, brief, explanation, options } } }
      report = gt(generalErrorReports, result.replace(/#/g, ''), false)

      // update store music
      if (report) {
        yield put({type: ADD_CHILD_NODE, node: { generalErrorReports, result }, path})
      }
    }

    const currentError = yield select(getError)
    // report this and skip modal
    if (currentError !== null) {
      return $badger.errorMetricsHandler(`PayLoadError action:[${JSON.stringify(action)}] err:[${JSON.stringify(e)}]`, true, null, { action, err: e })
    }

    // display modal; either from the call response or default timeout
    console.info('Got here Loading timeout', e)
    yield put(addError(ERRORS.loading_timeout(report || { terse: `${e.message} for path ${path}` }, result), true, path))
  }
}

function * searchSaga () {
  yield takeLatest(LOAD_SEARCH_NODE, loadSearchResultsNode)
}

export default searchSaga
